import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
// @ts-ignore
import * as styles from "../components/index.module.css"
import { DonateButton } from "../components/paypal"
import { UpBookIcon } from "../components/icon"

const DonatePage = () => (
  <Layout>
    <Seo title="Donate to Kyle Kelley for Santa Cruz City Schools" />
    <div className={styles.textCenter}>
      <UpBookIcon />
      <h1>
        <b>Donate to Kyle Kelley</b> for <b>Santa Cruz City Schools</b>
      </h1>
      <DonateButton />
    </div>
  </Layout>
)

export default DonatePage
